<template>
  <div :id="'gridContainer' + (withinModal ? '_inner_' : '') + formId" :class="skinName + '-grid-container '">
    <div v-if="!gridData && !noData" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div v-if="skinName == 'comment'">
      <h3 class="title mb-3" style="border-bottom: 1px solid #ccc">Comments</h3>
    </div>
    <div v-if="noData && showFormOnEmpty && !hasHadData">
      <h2 v-if="!skinName == 'comment'" class="title mb-3 mx-3">
        Add {{ !showSearchToggle ? 'New ' + entityTypeName : 'ECE professional' }}
      </h2>
      <div :class="isBusy ? 'disabled-area' : ''" v-if="formId > 0">
        <SearchToggle
          v-if="showSearchToggle()"
          v-on:showForm="testHideForm($event)"
          v-on:showAltForm="testShowSearchForm($event)"
          :section="section"
          :enrolledUsers="enrolledUsers"
        >
        </SearchToggle>
        <ResumeToggle
          v-if="showResumeToggle()"
          v-on:showForm="testHideForm($event)"
          v-on:showAltForm="testShowAltForm($event)"
        >
        </ResumeToggle>
        <ReferenceToggle
          v-if="showReferenceToggle()"
          v-on:showForm="testHideForm($event)"
          v-on:showAltForm="testShowAltForm($event)"
        >
        </ReferenceToggle>
        <wfrform
          v-if="
            (showForm && !showResumeToggle() && !showSearchToggle() && !showReferenceToggle()) ||
            (showAltForm && isReferenceRecord() && !showReferenceToggle())
          "
          :formid="showAltForm && isReferenceRecord() ? altFormId : formId"
          :entities="entity"
          @saved="formSaved($event)"
          @closed="formClosed($event)"
          :primaryEntity="localPrimaryEntity"
          @continueButton="$emit('continueButton')"
          :continueButtonName="continueButtonName"
          :showContinueButton="showContinueButton"
          :showSaveAndAssociateButton="showSaveAndAssociateButton"
          @associate="handleAssociate"
          @dirty="formDirty($event)"
          :firstTime="true"
          :parentFormId="parentFormId"
          :showParentForm="showParentPropsInForm"
          :parentProps="localParentProps"
        >
        </wfrform>
        <ResumeProfileEditor
          v-if="showAltForm && !showResumeToggle() && isResumeRecord()"
          :description="altFormDesc"
          :resumeId="activeid"
          :sections="altFormSections"
          :published="altFormPublished"
          @saved="resAltFormSaved($event)"
          @closed="formClosed($event)"
          :firstTime="true"
        >
        </ResumeProfileEditor>
      </div>
    </div>
    <div v-if="!noData || !showFormOnEmpty || hasHadData">
      <div :class="isBusy ? 'mb-3 disabled-area' : 'mb-3'">
        <div class="row mb-1">
          <div :class="!showLastUpdated ? 'order-2 col-6 text-right' : 'col-12 mb-1 text-right'" v-if="showAddButton">
            <button
              class="btn btn-primary"
              @click="addRecord()"
              v-show="!isEnrollmentRecord() || !section.recordIsLocked"
            >
              <icon :icon="'plus'" :size="'sm'"></icon> Add New
            </button>
          </div>
          <div class="col-6">
            <div :class="!showLastUpdated ? 'order-1' : ''" v-if="roleCanDisable && showDisable">
              <input
                type="checkbox"
                title="Include Disabled"
                v-model="includeDisabled"
                id="includeDisabled"
                @change="disabledCheckToggle($event.target.checked)"
              />
              <label for="includeDisabled" class="text-secondary mb-0 ml-1">Include Disabled</label>
            </div>
          </div>
          <div class="col-6 text-right mb-0" v-if="showLastUpdated">
            <label class="text-secondary mb-0"
              >Last Updated on {{ $formatGridDate(maxUpdatedDisplayDate, true, true) }}</label
            >
          </div>
        </div>
      </div>
      <div v-if="gridData" :class="isBusy ? 'disabled-area' : ''">
        <div v-if="multiRowSelect" class="mb-1 btn-group">
          <div v-for="action in tableActions" :key="action.verb">
            <button
              v-if="selected && selected.length && shouldShowAction(gridData, action)"
              :class="action.button.classes"
              :title="action.button.title"
              @click="tableAction(action.verb)"
            >
              <icon :icon="action.button.icon" class="mr-2" :size="'sm'"></icon>{{ action.button.title }}
            </button>
          </div>
        </div>
        <table
          class="table"
          v-if="!noData || hasHadData"
          :aria-multiselectable="multiRowSelect"
          :aria-colcount="columns ? (showActionColumn ? columns.length + 1 : columns.length) : 0"
        >
          <thead role="rowgroup" :class="skinName + '-grid-header'">
            <tr role="row">
              <th
                role="columnheader"
                scope="col"
                :aria-colindex="index + 1"
                v-for="(col, index) in visibleColumns()"
                :key="col.columnName"
                :class="'grid-header-column' + (index > 0 ? ' grid-extra-column' : '')"
              >
                <button
                  class="mouse-pointer btn font-weight-bold m-0 p-0 text-white"
                  @click="sortColName(col.columnName)"
                  style="font-size: 14px"
                  v-if="col.sortable"
                >
                  {{ col.columnName }}
                </button>
                <span v-if="!col.sortable" style="font-size: 14px" class="font-weight-bold m-0 p-0 text-white">
                  {{ col.columnName }}
                </span>
                <div style="display: inline">
                  <div style="display: inline" v-if="col.sortable">
                    <a
                      class="mouse-pointer mx-1 text-white"
                      :title="'Sort by ' + col.columnName"
                      @click="sort(col.columnName, sortDirections.ascending)"
                      v-if="sortColumn != col.columnName"
                    >
                      <icon :icon="'sort'" :size="'sm'"></icon>
                    </a>
                  </div>
                  <div style="display: inline" v-if="col.sortable">
                    <a
                      class="mouse-pointer mx-1 text-white"
                      :title="'Sort by ' + col.columnName + ' descending'"
                      @click="sort(col.columnName, sortDirections.descending)"
                      v-if="sortColumn == col.columnName && sortDirection == sortDirections.ascending"
                    >
                      <icon :icon="'caret-up'" :size="'sm'"></icon>
                    </a>
                  </div>
                  <div style="display: inline" v-if="col.sortable">
                    <a
                      class="mouse-pointer mx-1 text-white"
                      :title="'Sort by ' + col.columnName + ' ascending'"
                      @click="sort(col.columnName, sortDirections.ascending)"
                      v-if="sortColumn == col.columnName && sortDirection == sortDirections.descending"
                    >
                      <icon :icon="'caret-down'" :size="'sm'"></icon>
                    </a>
                  </div>
                  <div style="display: inline; float: right" v-if="col.filterable">
                    <button
                      :id="'filterButton' + getControlIdSuffix(index)"
                      :class="
                        'btn p-0 my-0 mouse-pointer mx-1 text-white' +
                        (filterColumn == col.columnName ? ' active-filter' : '')
                      "
                      :title="
                        filterColumn == col.columnName
                          ? 'Results currently filtered where ' + col.columnName + ' contains \'' + filterValue + '\''
                          : 'Filter results by ' + col.columnName
                      "
                      @click="setFilterWarning(col.columnName)"
                    >
                      <icon :icon="'filter'" :size="'sm'"></icon>
                    </button>
                    <b-popover
                      :target="'filterButton' + getControlIdSuffix(index)"
                      triggers="click blur"
                      placement="bottom"
                      custom-class="mt-3"
                      @shown="onFilterShown(col, index)"
                      :ref="'popover' + getControlIdSuffix(index)"
                      :container="'gridContainer' + (withinModal ? '_inner_' : '') + formId"
                    >
                      <template v-slot:title> Filter by {{ col.columnName }} </template>
                      <div class="popover-body">
                        {{ currentFilterWarning }}
                        <div class="ml-1" v-if="extraFilterWarning">
                          {{ extraFilterWarning }}
                        </div>
                      </div>
                      <form @submit.prevent="filterAccept(col, index)">
                        <div v-if="!col.formatDate && !col.formatNumber">
                          <input
                            type="text"
                            class="form-control"
                            name="filterInput"
                            :ref="'popover-input' + getControlIdSuffix(index)"
                          />
                        </div>
                        <div v-if="col.formatNumber">
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :ref="'popover-number-input' + getControlIdSuffix(index)"
                          />
                        </div>
                        <div v-if="col.formatDate">
                          <select
                            class="custom-select form-el mb-1"
                            :ref="'popover-date-ddl' + getControlIdSuffix(index)"
                          >
                            <option value="" selected disabled hidden>Operation</option>
                            <option :value="filterOperations.lessThan">Before</option>
                            <option :value="filterOperations.onDate">On</option>
                            <option :value="filterOperations.greaterThan">After</option>
                          </select>
                          <input
                            type="date"
                            class="form-control"
                            :ref="'popover-date-input' + getControlIdSuffix(index)"
                            min="1900-01-01"
                            max="2200-12-31"
                          />
                        </div>
                        <div class="my-2 float-right">
                          <button type="submit" class="btn btn-primary">Filter</button>
                        </div>
                      </form>
                    </b-popover>
                  </div>
                </div>
              </th>
              <th
                class="grid-header-column grid-extra-column"
                role="columnheader"
                scope="col"
                v-if="showActionColumn"
                :aria-colindex="columns ? columns.length + 1 : 1"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup" :class="disableTable ? 'disabled-area' : ''">
            <tr
              :class="
                'grid-tr ' +
                (idx % 2 == 0
                  ? isReflection(item)
                    ? 'bg-reflection '
                    : 'bg-white '
                  : isReflection(item)
                  ? 'bg-reflection '
                  : 'bg-light ') +
                (tableActions && tableActions.length > 0 ? 'grid-tr-multiselect' : '')
              "
              v-for="(item, idx) in gridData"
              ref="gridRow"
              :key="idx"
              role="row"
              :tabindex="multiRowSelect ? 0 : ''"
            >
              <td
                v-for="(col, index) in visibleColumns()"
                @click="multiRowSelect ? rowSelected(item, idx) : null"
                @keyup.space="multiRowSelect ? rowSelected(item, idx) : null"
                @keyup.enter="multiRowSelect ? rowSelected(item, idx) : null"
                :key="index"
                :class="(index > 0 ? 'grid-extra-column' : '') + (sortColumn == col.columnName ? ' sort-column' : '')"
                :aria-colindex="index + 1"
                :data-label="col.columnName"
              >
                <template v-if="addIconToColumn(col, item)">
                  <icon
                    :icon="'portrait'"
                    v-if="isReflection(item)"
                    :size="'lg'"
                    style="font-size: 1.6rem"
                    class="mr-1 tnpalBlue"
                    :title="'Reflection'"
                  ></icon>
                  {{ col.formatDate ? getDateColumnValue(col, item) : getColumnValue(col, item) }}
                  <img
                    src="../../images/icon/PROGRAM_PENDING.svg"
                    v-if="item.pending100 && col.propertyName === 'employerName'"
                    class="custom-svg mx-1"
                    title="Staff Role Pending"
                  />
                  <img
                    src="../../images/icon/PROGRAM_PENDING.svg"
                    v-if="
                      ['Pending Approval', 'Waitlisted'].includes(item.status) &&
                      ['status', 'sectionName'].includes(col.propertyName)
                    "
                    class="custom-svg mx-1"
                    :title="item.status === 'Pending Approval' ? 'Registration Pending Approval' : 'On Waitlist'"
                  />
                  <img
                    src="../../images/icon/PROGRAM_APPROVED.svg"
                    v-if="item.has100Role && ['employerName', 'fullName'].includes(col.propertyName)"
                    class="custom-svg mx-1"
                    title="Staff Role Approved"
                  />
                  <img
                    src="../../images/icon/PROGRAM_REJECTED.svg"
                    v-if="item.rejected100 && col.propertyName === 'employerName'"
                    class="custom-svg mx-1"
                    title="Staff Role Rejected"
                  />
                  <img
                    src="../../images/icon/PROGRAM_REMOVED.svg"
                    v-if="item.removed100 && col.propertyName === 'employerName'"
                    class="custom-svg mx-1"
                    title="Staff Role Ended"
                  />
                  <img
                    src="../../images/icon/DIRECTOR_PENDING.svg"
                    v-if="item.pending200 && col.propertyName === 'employerName'"
                    class="custom-svg mx-1"
                    title="Director Role Pending"
                  />
                  <img
                    src="../../images/icon/DIRECTOR_APPROVED.svg"
                    v-if="item.has200Role && ['employerName', 'fullName'].includes(col.propertyName)"
                    class="custom-svg mx-1"
                    title="Director Role Approved"
                  />
                  <img
                    src="../../images/icon/DIRECTOR_REJECTED.svg"
                    v-if="item.rejected200 && col.propertyName === 'employerName'"
                    class="custom-svg mx-1"
                    title="Director Role Rejected"
                  />
                  <img
                    src="../../images/icon/DIRECTOR_REMOVED.svg"
                    v-if="item.removed200 && col.propertyName === 'employerName'"
                    class="custom-svg mx-1"
                    title="Director Role Ended"
                  />
                  <img
                    src="../../images/icon/PROGRAM_PENDING.svg"
                    v-if="
                      item.verificationStatus &&
                      item.verificationStatus == 'Requested' &&
                      ['educationLevel', 'certificationName', 'careerLatticeLevel'].includes(col.propertyName)
                    "
                    class="custom-svg mx-1"
                    title="Verification Pending"
                  />
                  <img
                    src="../../images/icon/VERIFIED.svg"
                    v-if="
                      item.verified &&
                      !['school', 'employerName', 'startDate', 'careerLatticeLevel', 'title'].includes(col.propertyName)
                    "
                    class="custom-svg mx-1"
                    :title="entityTypeName + ' Verified'"
                  />
                  <img
                    src="../../images/icon/UNVERIFIED.svg"
                    v-if="
                      !item.verified &&
                      ['educationLevel', 'certificationName', 'careerLatticeLevel'].includes(col.propertyName) &&
                      (!item.verificationStatus || item.verificationStatus != 'Requested')
                    "
                    class="custom-svg mx-1"
                    :title="entityTypeName + ' Not Verified'"
                  />
                  <img
                    src="../../images/icon/PROGRAM_APPROVED.svg"
                    v-if="
                      item.verified &&
                      item.verificationStatus === 'Approved' &&
                      ['careerLatticeLevel'].includes(col.propertyName)
                    "
                    class="custom-svg mx-1"
                    :title="entityTypeName + ' Approved'"
                  />
                  <img
                    src="../../images/icon/PROGRAM_REJECTED.svg"
                    v-if="
                      item.rejected &&
                      !['school', 'employerName', 'startDate', 'careerLatticeLevel'].includes(col.propertyName)
                    "
                    class="custom-svg mx-1"
                    :title="entityTypeName + ' Rejected'"
                  />
                  <img
                    src="../../images/icon/PROGRAM_REJECTED.svg"
                    v-if="
                      !item.verified &&
                      item.verificationStatus === 'Rejected' &&
                      ['careerLatticeLevel'].includes(col.propertyName)
                    "
                    class="custom-svg mx-1"
                    :title="entityTypeName + ' Rejected'"
                  />
                  <img
                    src="../../images/icon/PROGRAM_REJECTED.svg"
                    v-if="item.rejected && col.propertyName === 'startDate'"
                    class="custom-svg mx-1"
                    title="Start Date Rejected"
                  />
                  <img
                    src="../../images/icon/VERIFIED.svg"
                    v-if="item.verified && col.propertyName === 'startDate'"
                    class="custom-svg mx-1"
                    title="Start Date Approved"
                  />
                  <img
                    src="../../images/icon/VERIFIED.svg"
                    v-if="item.verified && col.propertyName === 'title' && entityTypeName === 'Training Record'"
                    class="custom-svg mx-1"
                    title="Verified"
                  />
                  <img
                    src="../../images/icon/UNVERIFIED.svg"
                    v-if="
                      !item.verified &&
                      !item.rejected &&
                      (entityTypeName == 'Experience' || entityTypeName == 'Compliance Report') &&
                      col.propertyName === 'startDate'
                    "
                    class="custom-svg mx-1"
                    title="Start Date Not Verified"
                  />
                  <img
                    src="../../images/icon/TRAINTN_APPROVED.svg"
                    v-if="
                      item.hasTrainTNAccess && (col.propertyName === 'fullName' || col.propertyName === 'employerName')
                    "
                    class="custom-svg mx-1"
                    title="TrainTN Access"
                  />
                  <img
                    src="../../images/icon/ENDORSEMENT.svg"
                    v-if="item.isEndorsement && !item.isExpired && col.propertyName === 'certificationName'"
                    class="custom-svg mx-1"
                    title="AIMHiTN Endorsement"
                  />
                  <img
                    src="../../images/icon/ENDORSEMENT_INACTIVE.svg"
                    v-if="item.isEndorsement && item.isExpired && col.propertyName === 'certificationName'"
                    class="custom-svg mx-1"
                    title="Expired AIMHiTN Endorsement"
                  />
                  <icon
                    :icon="'upload'"
                    v-if="item.status === 'Uploaded (Draft)' && col.propertyName === 'status'"
                    :size="'sm'"
                    class="mx-1 tnpalBlue"
                    :title="'Uploaded'"
                  ></icon>
                  <icon
                    :icon="'hourglass-half'"
                    v-if="item.status === 'Submitted' && col.propertyName === 'status'"
                    :size="'sm'"
                    class="mx-1 tnpalBlue"
                    :title="'Submitted'"
                  ></icon>
                  <icon
                    :icon="'check'"
                    v-if="item.status === 'Approved' && col.propertyName === 'status'"
                    :size="'sm'"
                    class="mx-1 tnpalBlue"
                    :title="'Approved'"
                  ></icon>
                  <icon
                    :icon="'times'"
                    v-if="item.status === 'Declined' && col.propertyName === 'status'"
                    :size="'sm'"
                    class="mx-1 tnpalBlue"
                    :title="'Declined'"
                  ></icon>
                  <icon
                    :icon="'paperclip'"
                    v-if="filesAttached(col, item)"
                    :size="'sm'"
                    class="mx-1 tnpalBlue"
                    :title="'Files Attached'"
                  ></icon>
                  <icon :icon="'lock'" v-if="item.userLocked"></icon>
                </template>
                <template v-else-if="col.allowHtml">
                  <div v-html="getColumnValue(col, item)"></div>
                </template>
                <template v-else>
                  {{ col.formatDate ? getDateColumnValue(col, item) : getColumnValue(col, item) }}
                </template>
              </td>
              <td
                v-if="showActionColumn"
                :aria-colindex="columns ? columns.length + 1 : 1"
                class="grid-extra-column"
                data-label="Action"
              >
                <button
                  class="btn action-link mouse-pointer mx-1 p-0"
                  :title="'View ' + entityTypeName"
                  @click="viewRecord(item)"
                  v-if="showView && item.canView"
                >
                  <icon :icon="'eye'" :size="'sm'"></icon>
                </button>
                <button
                  class="btn action-link mouse-pointer mx-1 p-0"
                  :title="'Edit ' + entityTypeName"
                  @click="editRecord(item)"
                  v-if="showEdit && item.canEdit"
                >
                  <icon :icon="'edit'" :size="'sm'"></icon>
                </button>
                <button
                  class="btn action-link mouse-pointer mx-1 p-0"
                  :title="'Disable ' + entityTypeName"
                  @click="disableRecord(item.id, true)"
                  v-if="roleCanDisable && showDisable && !item.disabled && item.canDisable"
                >
                  <icon :icon="'ban'" :size="'sm'"></icon>
                </button>
                <button
                  class="btn action-link mouse-pointer mx-1 p-0"
                  :title="'Enable ' + entityTypeName"
                  @click="disableRecord(item.id, false)"
                  v-if="roleCanDisable && item.disabled && item.canDisable"
                >
                  <icon :icon="'check'" :size="'sm'"></icon>
                </button>
                <button
                  class="btn action-link mouse-pointer mx-1 p-0"
                  :title="'Delete ' + entityTypeName"
                  @click="deleteRecord(item.id)"
                  v-if="showDelete && item.canDelete"
                >
                  <icon :icon="'trash'" :size="'sm'"></icon>
                </button>
                <div class="d-inline" :id="'additionalWrapper_' + item.id">
                  <button
                    v-for="action in visibleAdditionalActionsOnColumn(item)"
                    :key="action.title"
                    class="btn action-link mouse-pointer mx-1 p-0"
                    :title="action.title"
                    @click="performAdditionalAction(action, item)"
                  >
                    <icon :icon="action.icon"></icon>
                  </button>
                  <button
                    :id="'moreActions_' + item.id"
                    class="btn action-link mouse-pointer mx-1 px-1 py-0"
                    title="Additional Actions"
                    v-if="hasAdditionalActions(item) && ellipsisAdditionalActions(item).length > 0"
                  >
                    <icon :icon="'ellipsis-v'" :size="'sm'"></icon>
                  </button>
                  <b-popover
                    :target="'moreActions_' + item.id"
                    :container="'additionalWrapper_' + item.id"
                    :ref="'action_popover_' + item.id"
                    triggers="click blur"
                    placement="bottom"
                    custom-class="mt-3"
                    v-if="hasAdditionalActions(item) && ellipsisAdditionalActions(item).length > 0"
                  >
                    <div v-for="action in ellipsisAdditionalActions(item)" :key="action.title" class="my-1">
                      <button
                        class="btn action-link mouse-pointer mx-1 p-0"
                        :title="action.title"
                        @click="performAdditionalAction(action, item, 'action_popover_' + item.id)"
                        style="font-size: 1.1em"
                      >
                        <icon :icon="action.icon" class="mr-2" :size="'lg'"></icon>{{ action.title }}
                      </button>
                    </div>
                  </b-popover>
                  <button
                    :id="'attendance_' + item.id"
                    class="btn action-link mouse-pointer mx-1 px-1 py-0"
                    title="Manage Attendance"
                    v-if="canManageAttendance(item)"
                  >
                    <icon :icon="'tasks'" :size="'sm'"></icon>
                  </button>
                  <b-popover
                    :target="'attendance_' + item.id"
                    :container="'additionalWrapper_' + item.id"
                    :ref="'action_popover_' + item.id"
                    triggers="click blur"
                    placement="bottom"
                    custom-class="mt-3"
                    v-if="canManageAttendance(item)"
                  >
                    <div key="clear" class="my-1">
                      <button
                        class="btn action-link mouse-pointer mx-1 p-0"
                        title="Clear Selection"
                        @click="setAttendance(item, 0, '')"
                        style="font-size: 1.1em"
                      >
                        &lt;clear&gt;
                      </button>
                    </div>
                    <div key="completed" class="my-1">
                      <button
                        class="btn action-link mouse-pointer mx-1 p-0"
                        title="Completed"
                        @click="setAttendance(item, 1, 'Completed')"
                        style="font-size: 1.1em"
                      >
                        Completed
                      </button>
                    </div>
                    <div key="partially_completed" class="my-1">
                      <button
                        class="btn action-link mouse-pointer mx-1 p-0"
                        title="Partially Completed"
                        @click="setAttendance(item, 2, 'Partially Completed')"
                        style="font-size: 1.1em"
                      >
                        Partially Completed
                      </button>
                    </div>
                    <div key="absent" class="my-1">
                      <button
                        class="btn action-link mouse-pointer mx-1 p-0"
                        title="Absent"
                        @click="setAttendance(item, 3, 'Absent')"
                        style="font-size: 1.1em"
                      >
                        Absent
                      </button>
                    </div>
                  </b-popover>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="multiRowSelect">
          <b-button
            class="mr-2"
            v-if="allowSelectAll && selected && selected.length < total && selected.length < pageSize"
            variant="wfr"
            @click="selectAllRows"
            >Select All</b-button
          >
          <b-button v-if="selected && selected.length && allowClearSelection" variant="wfr" @click="clearSelected"
            >Clear Selected</b-button
          >
        </div>
        <div class="export-button-container">
          <b-button
            variant="wfr"
            v-if="!noData && allowDownload"
            @click="downloadCsv"
            class="float-right"
            title="Download CSV"
            id="csv"
          >
            <icon icon="file-csv" size="lg"></icon>
          </b-button>
          <b-button
            variant="wfr"
            v-if="!noData && allowDownload"
            @click="downloadPdf"
            class="float-right mr-2"
            title="Download PDF"
            id="pdf"
          >
            <icon icon="file-pdf" size="lg"></icon>
          </b-button>
        </div>
        <Paging
          :firstOnPage="firstOnPage"
          :lastOnPage="lastOnPage"
          :total="total"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :pageSizeChanged="pageSizeChanged"
          :loadPage="loadPage"
          v-if="showPagination"
        ></Paging>
      </div>
      <modal
        v-show="isModalVisible"
        @close="closeModal"
        :isModalVisible="this.isModalVisible"
        :withinModal="withinModal"
        v-if="formId > 0"
      >
        <h2 slot="header" v-if="locale === 'tn' && showResumeToggle()">Add Resume</h2>
        <h2 slot="header" v-else-if="locale === 'tn' && showReferenceToggle()">Add References</h2>
        <h2 slot="header" v-else>
          {{ addmode ? 'Add' : viewmode ? 'View' : 'Edit' }}
          {{ !showSearchToggle() ? (addmode ? 'New ' : '') + entityTypeName : 'ECE professional' }}
        </h2>
        <SearchToggle slot="body"
                      v-if="showSearchToggle()"
                      :section="section"
                      :enrolledUsers="enrolledUsers"></SearchToggle>
        <ResumeToggle slot="body"
                      v-if="showResumeToggle()"
                      v-on:showForm="testHideForm($event)"
                      v-on:showAltForm="testShowAltForm($event)"></ResumeToggle>
        <ResumeProfileEditor slot="body"
                             v-if="showAltForm && !showResumeToggle() && !showSearchToggle() && isResumeRecord()"
                             :startinviewmode="viewmode"
                             :showEditButton="canEditRecord"
                             :resumeId="activeid"
                             :sections="altFormSections"
                             :fileKey="fileKey"
                             :description="altFormDesc"
                             :published="altFormPublished"
                             @saved="resAltFormSaved($event)"
                             :firstTime="false"></ResumeProfileEditor>
        <ReferenceToggle slot="body"
                         v-if="showReferenceToggle()"
                         v-on:showForm="testHideForm($event)"
                         v-on:showAltForm="testShowAltForm($event)"></ReferenceToggle>
        <wfrform slot="body"
                 v-if="(showForm && !showResumeToggle() && !showSearchToggle() && !showReferenceToggle()) || (showAltForm && isReferenceRecord() && !showReferenceToggle())"
                 :formid="showAltForm && isReferenceRecord() ? altFormId : formId"
                 :entities="entity"
                 @saved="formSaved($event)"
                 :key="activeid"
                 :startinviewmode="viewmode"
                 @editing="gridEditing($event)"
                 :primaryEntity="localPrimaryEntity"
                 @continueButton="$emit('continueButton')"
                 :continueButtonName="continueButtonName"
                 :showContinueButton="showContinueButton && !isModalVisible"
                 :showSaveAndAssociateButton="showSaveAndAssociateButton"
                 @dirty="formDirty($event)"
                 @associate="handleAssociate"
                 :showEditButton="canEditRecord"
                 :showComments="showComments"
                 :addComments="addComments"
                 :firstTime="false"
                 :showParentForm="showParentPropsInForm"
                 :parentProps="localParentProps"
                 :enrolledUsers="enrolledUsers"
                 :parentFormId="parentFormId">
        </wfrform>
      </modal>
      <div class="text-center m-5" v-if="noData && (showNoDataWarning || hasHadData)">No Data to Display</div>
    </div>
    <div :class="(isBusy ? 'disabled-area ' : '') + 'pb-3'" v-if="showContinueButton && !(noData && showFormOnEmpty)">
      <div class="w-100">
        <div class="float-right" v-if="true">
          <button class="btn btn-primary" @click="$emit('continueButton')" id="btnContinue">
            {{ continueButtonName }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="!noData && !isBusy && showActionColumn" class="text-center" style="color: #6c757d">
      <span v-if="showViewHelp"><icon :icon="'eye'" :size="'sm'"></icon> View</span>
      <span class="ml-2 ml-sm-5" v-if="showEditHelp"><icon :icon="'edit'" :size="'sm'"></icon> Edit</span>
      <span class="ml-2 ml-sm-5" v-if="showDeleteHelp"><icon :icon="'trash'" :size="'sm'"></icon> Delete</span>
      <span class="ml-2 ml-sm-5" v-if="showAdditionalActionHelp"
        ><icon :icon="'ellipsis-v'" :size="'sm'"></icon> Additional Actions</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { locale } from '@scripts/localized';
import Paging from '@components/utility/paging.vue';
import SearchToggle from '@components/dashboard-items/management/search-users.vue';
import ResumeToggle from '@components/dashboard-items/resume-items/resume-toggle.vue';
import ReferenceToggle from '@components/dashboard-items/resume-items/reference-toggle.vue';
import ResumeProfileEditor from '@components/dashboard-items/resume-items/resume-profile-editor.vue';
export default {
  name: 'Grid',
  components: {
    Paging,
    SearchToggle,
    ResumeToggle,
    ResumeProfileEditor,
    ReferenceToggle
  },
  computed: {
    ...mapGetters(['roles', 'activeRole', 'isAuthenticated']),
    totalPages: function () {
      return Math.ceil(this.total / this.pageSize);
    },
    firstOnPage: function () {
      return (this.currentPage - 1) * this.pageSize + 1;
    },
    lastOnPage: function () {
      const max = this.currentPage * this.pageSize;
      return max > this.total ? this.total : max;
    },
    primaryEntityId: function () {
      if (this.primaryEntity && this.primaryEntity.Id) return this.primaryEntity.Id;
      return '';
    },
    displayName: function () {
      if (this.localPrimaryEntity && this.localPrimaryEntity.Id) return this.localPrimaryEntity.DisplayValue;
      return '';
    },
    hasAdminRole: function () {
      if (this.roles && this.roles.length > 0 && this.roles.includes('AdminUser')) return true;
      return false;
    },
    hasTrainerRole: function () {
      if (this.roles && this.roles.length > 0 && this.roles.includes('250')) return true;
      return false;
    },
    roleCanDisable: function () {
      if (
        this.roles &&
        this.roles.length > 0 &&
        (this.roles.includes('AdminUser') ||
          this.roles.includes('400') ||
          this.roles.includes('500') ||
          (this.roles.includes('100') && [7].includes(this.entityTypeId) && ['tn'].includes(locale)) ||
          (this.roles.includes('250') && [23, 24, 26].includes(this.entityTypeId)))
      )
        return true;
      return false;
    },
    filterColumnObject: function () {
      if (!this.filterColumn || !this.columns) return null;
      for (let i = 0; i < this.columns.length; i++) {
        const column = this.columns[i];
        if (column.columnName && column.columnName == this.filterColumn) return column;
      }
      // default to first column if we drop out of loop
      return this.columns[0];
    },
    sortColumnObject: function () {
      if (!this.sortColumn || !this.columns) return null;
      for (let i = 0; i < this.columns.length; i++) {
        const column = this.columns[i];
        if (column.columnName && column.columnName == this.sortColumn) return column;
      }
      // default to first column if we drop out of loop
      return this.columns[0];
    },
    showViewHelp: function () {
      if (
        this.showView &&
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '100'
      )
        return true;
      return false;
    },
    showEditHelp: function () {
      if (
        this.showEdit &&
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        (this.activeRole.role.roleName == '100' ||
          this.activeRole.role.roleName == '350' ||
          this.activeRole.role.roleName == '375' ||
          this.activeRole.role.roleName == '400' ||
          this.activeRole.role.roleName == '500')
      )
        return true;
      return false;
    },
    showDeleteHelp: function () {
      if (
        this.showDelete &&
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '100'
      )
        return true;
      return false;
    },
    showAdditionalActionHelp: function () {
      if (
        !this.additionalActionsOnColumn &&
        this.additionalActions &&
        this.additionalActions.length > 0 &&
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '100'
      )
        return true;
      return false;
    }
  },
  props: {
    entityTypeId: {
      type: Number,
      required: true
    },
    showActionColumn: {
      type: Boolean,
      default: true
    },
    parentFormId: {
      type: Number
    },
    formId: {
      type: Number,
      required: true
    },
    altFormId: {
      type: Number
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    showView: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showDisable: {
      type: Boolean,
      default: true
    },
    showLastUpdated: {
      type: Boolean,
      default: false
    },
    entityTypeName: {
      type: String,
      required: true
    },
    primaryEntity: {
      type: Object,
      default() {
        return {
          Id: '',
          EntityTypeId: 0,
          DisplayValue: ''
        };
      }
    },
    section: {
      type: Object,
      default: () => {}
    },
    showFormOnEmpty: {
      type: Boolean,
      default: false
    },
    showContinueButton: {
      type: Boolean,
      default: false
    },
    showSaveAndAssociateButton: {
      type: Boolean,
      default: false
    },
    rowClick: {
      type: Function,
      default: undefined
    },
    continueButtonName: {
      type: String,
      default: 'Continue'
    },
    basePageSize: {
      type: Number,
      default: 5
    },
    baseCurrentPage: {
      type: Number,
      default: 1
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    additionalActions: {
      type: Array,
      default: () => []
    },
    additionalActionsOnColumn: {
      type: Boolean,
      default: false
    },
    checkForAdditionalActions: {
      type: Function,
      default: () => {
        return true;
      }
    },
    multiRowSelect: {
      type: Boolean,
      default: false
    },
    allowSelectAll: {
      type: Boolean,
      default: false
    },
    allowClearSelection: {
      type: Boolean,
      default: false
    },
    tableActions: {
      type: Array,
      default: () => []
    },
    additionalShowColumns: {
      type: Array,
      default: () => []
    },
    withinModal: {
      type: Boolean,
      default: false
    },
    showComments: {
      type: Boolean,
      default: false
    },
    canAddComments: {
      type: Function,
      default: () => {
        return false;
      }
    },
    getDynPrimaryEntity: {
      type: Function,
      default: undefined
    },
    getDynParentProps: {
      type: Function,
      default: undefined
    },
    showNoDataWarning: {
      type: Boolean,
      default: true
    },
    allowDownload: {
      type: Boolean,
      default: true
    },
    skinName: {
      type: String,
      default: 'wfr'
    },
    defaultFilters: {
      type: Array,
      default: () => []
    },
    exportFilters: {
      type: Array,
      default: () => []
    },
    showParentForm: {
      type: Boolean,
      default: false
    },
    showParentPropsInForm: {
      type: Boolean,
      default: false
    },
    parentProps: {
      type: Array,
      default: () => []
    },
    altGridId: {
      type: Number,
      default: 0
    },
    setCurrentLevel: {
      type: Function,
      default: () => {
        return '';
      }
    },
    setVerificationIcon: {
      type: Function,
      default: () => {
        return '';
      }
    }
  },
  data() {
    return {
      gridData: null,
      total: 0,
      maxUpdatedDisplayDate: '',
      pageSize: 5,
      currentPage: 1,
      isBusy: false,
      columns: null,
      noData: false,
      isModalVisible: false,
      entity: [{ Id: '', EntityTypeId: this.entityTypeId, DisplayValue: '' }],
      activeid: '',
      viewmode: false,
      includeDisabled: false,
      addmode: false,
      errors: null,
      isDirty: false,
      canEditRecord: false,
      sortDirections: {
        ascending: 'Ascending',
        descending: 'Descending',
        descendingNumeric: 1
      },
      sortColumn: '',
      sortDirection: 'Ascending',
      filterColumn: '',
      filterValue: '',
      filterOperation: 'None',
      filterOperations: {
        none: 'None',
        onDate: 'OnDate',
        contains: 'Contains',
        greaterThan: 'GreaterThan',
        lessThan: 'LessThan',
        equals: 'Equals'
      },
      currentFilterWarning: '',
      extraFilterWarning: '',
      hasHadData: false,
      enrolledUsers: [],
      addComments: false,
      showForm: true,
      showAltForm: false,
      altFormSections: null,
      fileKey: '',
      altFormDesc: '',
      altForm: null,
      altFormPublished: false,
      inForm: false,
      locale: locale,
      selected: [],
      disableTable: false
    };
  },
  methods: {
    async handleAssociate(result){
      this.$emit('associate', result);
    },
    async loadPage(page) {
      return await new Promise((resolve, reject) => {
        this.currentPage = page;
        const from = (page - 1) * this.pageSize;
        const to = from + this.pageSize;
        const body = {
          from: from,
          to: to,
          formId: this.formId,
          entityTypeId: this.entityTypeId,
          includeDisabled: this.includeDisabled,
          multiRowSelect: this.multiRowSelect,
          allowSelectAll: this.allowSelectAll,
          allowClearSelection: this.allowClearSelection,
          primaryEntity: this.primaryEntity,
          sortInfo: {
            sortColumn: this.sortColumnObject,
            sortDirection: this.sortDirection
          },
          filterInfo: {
            filterColumn: this.filterColumnObject,
            filterValue: this.filterValue,
            operation: this.filterOperation
          },
          defaultFilters: this.defaultFilters,
          altGridId: this.altGridId
        };
        this.isBusy = true;
        this.$store
          .dispatch('gridDataRequest', body)
          .then((response) => {
            this.isBusy = false;
            if (response == 'No Data') {
              this.noData = true;
            } else {
              this.noData = false;
              this.gridData = response.gridData;
              this.columns = response.columns;
              this.$emit('setCurrentPage', page);
              this.$emit('setCurrentLevel', response.gridData[0].careerLatticeLevel);
              this.$emit(
                'setVerificationIcon',
                response.gridData[0].verificationStatus,
                this.getVerificationIcon(response.gridData[0].verificationStatus)
              );
            }
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
        this.clearSelected();
      });
    },
    async loadSummary() {
      return await new Promise((resolve, reject) => {
        const body = {
          formId: this.formId,
          entityTypeId: this.entityTypeId,
          includeDisabled: this.includeDisabled,
          multiRowSelect: this.multiRowSelect,
          allowSelectAll: this.allowSelectAll,
          allowClearSelection: this.allowClearSelection,
          primaryEntity: this.primaryEntity,
          filterInfo: {
            filterColumn: this.filterColumnObject,
            filterValue: this.filterValue,
            operation: this.filterOperation
          },
          defaultFilters: this.defaultFilters,
          altGridId: this.altGridId
        };
        this.isBusy = true;
        this.$store
          .dispatch('gridSummaryRequest', body)
          .then(async (response) => {
            this.total = response.total;
            this.maxUpdatedDisplayDate = response.maxUpdatedDate;
            this.noData = this.total == 0;
            if (!this.noData) {
              this.hasHadData = true;
              await this.loadPage(this.currentPage);
            } else {
              this.gridData = {};
              this.isBusy = false;
              this.clearSelected();
            }
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
            this.clearSelected();
          });
      });
    },
    getColumnValue(col, item) {
      let result = '';
      try {
        if (col && col.propertyName) result = col.propertyName.split('.').reduce((a, b) => a[b], item);
      } catch {
        return '';
      }
      return result;
    },
    getDateColumnValue(col, item) {
      let result = '';
      try {
        if (col && col.propertyName) result = col.propertyName.split('.').reduce((a, b) => a[b], item);
        if (result) result = this.$formatGridDate(result, col.isTimeStamp, col.showTime);
      } catch {
        return '';
      }
      return result;
    },
    pageSizeChanged(size) {
      if (size && !isNaN(size)) {
        this.pageSize = parseInt(size);
      }
      this.$emit('setPageSize', size);
      this.loadPage(1);
    },
    async viewRecord(item) {
      if (this.showView && item.canView) {
        if (this.isResumeRecord()) {
          this.testHideForm(!item.isGenerated);
          if (item.isGenerated) {
            this.callResumeRecord(item, true);
            return;
          }
        }
        if (this.isReferenceRecord()) {
          if (!item.fileUpload) this.testShowAltForm(true);
          else this.testShowAltForm(false);
        }
        this.canEditRecord = (item.canEdit && this.showEdit) || true;
        this.viewmode = true;
        this.openExisting(item);
      }
    },
    async editRecord(item) {
      if (this.isResumeRecord()) {
        this.testHideForm(!item.isGenerated);
        if (item.isGenerated) {
          this.callResumeRecord(item, false);
          return;
        }
      }
      if (this.isReferenceRecord()) {
        if (!item.fileUpload) this.testShowAltForm(true);
        else this.testShowAltForm(false);
      }
      this.canEditRecord = true;
      this.openExisting(item);
    },
    async callResumeRecord(item, viewmode) {
      this.isBusy = true;
      this.$store
        .dispatch('viewResumeFieldsRequest', { resumeId: item.id })
        .then((response) => {
          this.isBusy = false;
          this.canEditRecord = item.canEdit && !item.isPublished;
          this.viewmode = viewmode;
          this.fileKey = item.fileKey;
          this.altFormDesc = item.description;
          this.altFormPublished = item.isPublished;
          this.openExisting(item);
          this.testShowAltForm(response);
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async openExisting(item) {
      if (typeof this.getDynPrimaryEntity === 'function') this.localPrimaryEntity = this.getDynPrimaryEntity(item);
      if (item.courseId && this.localPrimaryEntity !== this.primaryEntity) {
        const body = { trainingId: item.courseId };
        this.$store.dispatch('coursePropsRequest', body).then((result) => {
          this.localParentProps = result;
          this.entity = [{ Id: item.id, EntityTypeId: this.entityTypeId }];
          this.activeid = item.id;
          this.addComments = this.canAddComments(item);
          this.showModal();
        });
      } else {
        this.entity = [{ Id: item.id, EntityTypeId: this.entityTypeId }];
        this.activeid = item.id;
        this.addComments = this.canAddComments(item);
        this.showModal();
      }
    },
    async deleteRecord(id) {
      const confirmed = await new Promise((resolve, reject) => {
        const options = { title: 'Warning', cancelLabel: 'Cancel' };
        this.$dialogs
          .confirm('Are you sure you want to delete?', options)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            resolve(false);
          });
      });
      if (confirmed) {
        if (!this.localPrimaryEntity) return;
        const body = {
          entityTypeId: this.entityTypeId,
          entityId: id,
          primaryEntityId: this.localPrimaryEntity.Id
        };
        this.isBusy = true;
        this.$store
          .dispatch('gridDeleteRequest', body)
          .then((response) => {
            this.loadSummary();
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    rowSelected(item, index) {
      if (item) {
        if (this.selected.includes(item.id)) {
          this.$refs.gridRow[index].classList.replace('grid-tr-selected', 'grid-tr');
          this.$refs.gridRow[index].ariaSelected = false;
          this.selected.splice(this.selected.indexOf(item), 1);
        } else {
          this.$refs.gridRow[index].classList.replace('grid-tr', 'grid-tr-selected');
          this.$refs.gridRow[index].ariaSelected = true;
          this.selected.push(item.id);
        }
      }
    },
    selectAllRows() {
      this.disableTable = true;
      this.selected = [];
      if (!this.gridData) return;
      for (let i = 0; i < this.gridData.length; i++) {
        this.$refs.gridRow[i].classList.replace('grid-tr', 'grid-tr-selected');
        const item = this.gridData[i].id;
        const found = this.selected.includes(item);
        if (!found) this.selected.push(item);
      }
    },
    clearSelected() {
      if (this.gridData) {
        for (let i = 0; i < this.gridData.length; i++) {
          this.$refs.gridRow[i].classList.replace('grid-tr-selected', 'grid-tr');
        }
      }
      this.selected = [];
      this.disableTable = false;
    },
    tableAction(eventVal) {
      if (eventVal) {
        const testVal = this.tableActions.find((a) => a.verb === eventVal).verb;
        if (testVal) {
          //setting the constant is a hack, but it seems to force await the completion of tableAction in parent
          const res = this.$emit('tableAction', testVal, this.selected, this.gridData);
        }
      }
    },
    addRecord() {
      if (this.isEnrollmentRecord()) {
        //override behavior
        this.section = this._props.section;
        if (this.noData === false)
          this.enrolledUsers = this.gridData.filter((gd) => gd.status === 'Registered').map((gd) => gd.userId);
        this.testHideForm(false);
      }
      this.addmode = true;
      this.activeid = '';
      this.showModal();
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal() {
      const reloadGrid = this.isDirty;
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.inForm = false;
        this.viewmode = false;
        this.addmode = false;
        this.entity = [{ Id: '', EntityTypeId: this.entityTypeId, DisplayValue: '' }];
        this.activeid = 'closed';
        if (this.isEnrollmentRecord()) {
          this.showForm = true;
        }
        if (reloadGrid) this.loadSummary();
      }
    },
    reload(resetSection) {
      if (this.$parent.reloadEnrollGrid) this.$parent.reloadEnrollGrid(resetSection);
      else if (this.$parent.$parent.reloadEnrollGrid) this.$parent.$parent.reloadEnrollGrid(resetSection);
      else if (this.$parent.$parent.$parent.reloadEnrollGrid)
        this.$parent.$parent.$parent.reloadEnrollGrid(resetSection);
    },
    formSaved(data) {
      this.closeModal();
      this.loadSummary();
    },
    formClosed(data) {
      this.activeid = 0;
      this.inForm = false;
    },
    resAltFormSaved(data) {
      this.loadSummary();
    },
    async disableRecord(id, disable) {
      let confirmed = true;
      if (disable)
        confirmed = await new Promise((resolve, reject) => {
          const options = { title: 'Warning', cancelLabel: 'Cancel' };
          this.$dialogs
            .confirm('Are you sure you want to disable?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
      if (confirmed) {
        const body = {
          disable: disable,
          entityTypeId: this.entityTypeId,
          entityId: id,
          primaryEntityId: this.localPrimaryEntity.Id
        };
        this.isBusy = true;
        this.$store
          .dispatch('gridDisableRequest', body)
          .then((response) => {
            this.loadSummary();
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    disabledCheckToggle(val) {
      this.includeDisabled = val;
      this.loadSummary();
    },
    gridEditing(event) {
      this.viewmode = false;
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && !this.viewmode && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
    sort(column, direction) {
      this.sortColumn = column;
      this.sortDirection = direction;
      this.loadSummary();
    },
    sortColName(column) {
      if (this.sortColumn == column) {
        this.sortDirection =
          this.sortDirection == this.sortDirections.ascending
            ? this.sortDirections.descending
            : this.sortDirections.ascending;
      } else {
        this.sortColumn = column;
        this.sortDirection = this.sortDirections.ascending;
      }
      this.loadSummary();
    },
    onFilterShown(col, index) {
      const column = col.columnName;
      if (!col.formatDate && !col.formatNumber) {
        const textInput = 'popover-input' + this.getControlIdSuffix(index);
        const targetRef = this.$refs[textInput];
        if (targetRef && targetRef[0]) {
          const input = targetRef[0];
          if (this.filterValue && this.filterColumn == column) {
            input.value = this.filterValue;
          }
          input.focus();
          const length = input.value.length;
          input.setSelectionRange(length, length);
        }
      } else if (col.formatNumber) {
        const numberInput = 'popover-number-input' + this.getControlIdSuffix(index);
        const numTargetRef = this.$refs[numberInput];
        if (numTargetRef && numTargetRef[0]) {
          const numInput = numTargetRef[0];
          if (this.filterValue && this.filterColumn == column) {
            numInput.value = this.filterValue;
          }
          numInput.focus();
        }
      } else {
        const dateOperation = 'popover-date-ddl' + this.getControlIdSuffix(index);
        const dateInput = 'popover-date-input' + this.getControlIdSuffix(index);
        const ddl = this.$refs[dateOperation];
        const date = this.$refs[dateInput];
        if (ddl && ddl[0]) {
          const input = ddl[0];
          if (this.filterOperation && this.filterColumn == column) {
            input.value = this.filterOperation;
          }
          input.focus();
        }
        if (date && date[0]) {
          const input = date[0];
          if (this.filterValue && this.filterColumn == column) {
            let filter = this.filterValue;
            if (col.isTimeStamp) {
              const d = new Date(filter);
              d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
              const final = new Date(d);
              filter = final.toISOString().split('T')[0];
            }
            input.value = filter;
          }
        }
      }
    },
    setFilterWarning(column) {
      this.currentFilterWarning = 'Enter the ' + column + ' value by which to filter the results';
      this.extraFilterWarning = '';
      if (this.filterColumn && this.filterValue) {
        if (this.filterColumn != column) {
          this.extraFilterWarning =
            this.extraFilterWarning + '\u2022 The current filter on ' + this.filterColumn + ' will be cleared\n';
        }
        this.extraFilterWarning = this.extraFilterWarning + '\u2022 To clear all filters, submit an empty value';
      }
    },
    filterAccept(col, index) {
      const column = col.columnName;
      const textInput = 'popover-input' + this.getControlIdSuffix(index);
      const numberInput = 'popover-number-input' + this.getControlIdSuffix(index);
      const popover = 'popover' + this.getControlIdSuffix(index);
      const dateOperation = 'popover-date-ddl' + this.getControlIdSuffix(index);
      const dateInput = 'popover-date-input' + this.getControlIdSuffix(index);

      const oldFilterColumn = this.filterColumn;
      const oldFilterValue = this.filterValue;
      const oldFilterOperation = this.filterOperation;
      let newValue = '';
      let newOperation = '';
      if (!col.formatDate && !col.formatNumber) {
        const input = this.$refs[textInput];
        if (input && input[0] && input[0].value) {
          newValue = input[0].value;
          newOperation = this.filterOperations.contains;
        }
      } else if (col.formatNumber) {
        const numInput = this.$refs[numberInput];
        if (numInput && numInput[0] && numInput[0].value) {
          newValue = numInput[0].value;
          newOperation = this.filterOperations.equals;
        }
      } else {
        const ddl = this.$refs[dateOperation];
        const date = this.$refs[dateInput];
        if (ddl && ddl[0] && ddl[0].value) {
          newOperation = ddl[0].value;
        }
        if (date && date[0] && date[0].value) {
          let selectedVal = date[0].value;
          if (col.isTimeStamp) {
            const d = new Date(selectedVal);
            d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
            const final = new Date(d);
            selectedVal = final.toISOString().replace('T', ' ').replace('.000Z', '');
          }
          newValue = selectedVal;
        }
      }
      if (newValue && newOperation) {
        this.filterColumn = column;
        this.filterValue = newValue;
        this.filterOperation = newOperation;
      } else {
        this.filterColumn = '';
        this.filterValue = '';
        this.filterOperation = this.filterOperations.none;
      }
      const window = this.$refs[popover];
      if (window && window[0] && window[0].$emit) {
        window[0].$emit('close');
      }
      if (
        !(
          oldFilterColumn == this.filterColumn &&
          oldFilterValue == this.filterValue &&
          oldFilterOperation == this.filterOperation
        )
      ) {
        this.currentPage = 1;
        this.$emit('setCurrentPage', 1);
        //if the filter changed, load the grid
        this.loadSummary();
      }
    },
    getFileName(extension) {
      const fileName = this.$timestampedReportName('TNPAL', this.entityTypeName, extension);
      return fileName;
    },
    downloadCsv() {
      const body = {
        formId: this.formId,
        entityType: this.entityTypeId,
        primaryEntityId: this.primaryEntityId,
        filters: this.exportFilters
      };
      this.isBusy = true;
      this.$store
        .dispatch('formFlatFileRequest', body)
        .then((response) => {
          this.isBusy = false;
          if (response && response.file) {
            const fileName = this.getFileName('csv');
            this.saveCsv(fileName, response.file);
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    saveCsv(filename, data) {
      const blob = new Blob([data], { type: 'text/csv' });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    downloadPdf() {
      const d = new Date();
      const body = {
        formId: this.formId,
        entityType: this.entityTypeId,
        primaryEntityId: this.primaryEntityId,
        filters: this.exportFilters,
        reportName: this.entityTypeName,
        reportTime: this.$dateTimestampReport(),
        fileType: 'PDF'
      };
      this.isBusy = true;
      this.$store
        .dispatch('formFlatFileRequest', body)
        .then((response) => {
          this.isBusy = false;
          if (response && response.file) {
            const fileName = this.getFileName('pdf');
            this.savePdf(fileName, response.file);
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    savePdf(strFileName, strData) {
      const pdfData = 'data:application/pdf;base64,' + escape(strData);

      const link = document.createElement('a');
      link.href = pdfData;
      link.download = strFileName;
      link.click();
    },
    hasAdditionalActions(item) {
      return this.additionalActions && this.additionalActions.length > 0 && this.checkForAdditionalActions(item);
    },
    isReflection(item) {
      if (item.commentType && item.commentType == 'Reflection') {
        return true;
      }
      return false;
    },
    filesAttached(col, item) {
      if (
        col.propertyName == 'educationLevel' ||
        col.propertyName == 'certificationName' ||
        col.propertyName == 'fileName'
      )
        if (item.fileUpload) return true;
      return false;
    },
    performAdditionalAction(action, item, popoverId) {
      if (this.multiRowSelect) this.clearSelected();
      let popover;
      if (popoverId) popover = this.$refs[popoverId];
      if (popover && popover[0] && popover[0].$emit) {
        const focusEl = document.getElementById('moreActions_' + item.id);
        popover[0].$emit('close');
        if (focusEl) focusEl.focus();
      }
      if (action && action.click && typeof action.click === 'function') {
        action.click(item);
      }
    },
    shouldShowAction(item, action) {
      if (action.check) return action.check(item);
      return true;
    },
    getVerificationIcon(status) {
      switch (status) {
        case 'Requested':
          return 'hourglass-half';
        case 'Approved':
          return 'check-circle';
        case 'Rejected':
          return 'times';
        default:
          return '';
      }
    },
    addIconToColumn(col, item) {
      return (
        col.propertyName == 'employerName' ||
        col.propertyName == 'fullName' ||
        col.propertyName == 'certificationName' ||
        col.propertyName == 'school' ||
        col.propertyName == 'status' ||
        col.propertyName == 'content' ||
        col.propertyName == 'educationLevel' ||
        col.propertyName == 'certificationName' ||
        col.propertyName == 'fileName' ||
        col.propertyName == 'startDate' ||
        col.propertyName == 'status' ||
        col.propertyName == 'sectionName' ||
        col.propertyName == 'careerLatticeLevel' ||
        col.propertyName == 'title'
      );
    },
    shouldShowColumn(column) {
      const exemption = this.additionalShowColumns.some((col) => col == column.propertyName);
      if (column.hidden && !exemption) {
        return false;
      }
      return true;
    },
    getControlIdSuffix(index) {
      return '_' + (this.withinModal ? '_inner_' : '') + '_' + this.formId + '_' + index;
    },
    visibleColumns: function () {
      // resolves (vue/no-use-v-if-with-v-for)
      if (this.columns) return this.columns.filter((x) => this.shouldShowColumn(x));
      return [];
    },
    visibleAdditionalActionsOnColumn(item) {
      const list = this.additionalActions.filter(
        (x) => this.hasAdditionalActions(item) && this.shouldShowAction(item, x)
      );
      if (this.additionalActionsOnColumn) return list;
      else {
        const ct = this.visibleCount(item);
        if (list.length > ct) return list.slice(0, ct);
        else return list;
      }
    },
    visibleAdditionalItems(item) {
      return this.additionalActions.filter((x) => this.shouldShowAction(item, x));
    },
    visibleAdditionalItemLength(item) {
      const addlItems = this.additionalActions.filter((x) => this.shouldShowAction(item, x));
      return addlItems.length;
    },
    visibleCount(item) {
      let count = 3;
      if (this.showView && item.canView) count--;
      if (this.showEdit && item.canEdit) count--;
      if (this.roleCanDisable && this.showDisable && !item.disabled && item.canDisable) count--;
      if (this.showDelete && item.canDelete) count--;
      return count >= 0 ? count : 0;
    },
    ellipsisAdditionalActions(item) {
      if (this.additionalActionsOnColumn) return [];
      const ct = this.visibleCount(item);
      const list = this.additionalActions.filter(
        (x) => this.hasAdditionalActions(item) && this.shouldShowAction(item, x)
      );
      if (list.length <= ct) return [];
      return list.slice(ct);
    },
    isReferenceRecord() {
      return this.entityTypeName == 'Reference';
    },
    isResumeRecord() {
      return this.entityTypeName == 'Resume';
    },
    isEnrollmentRecord() {
      return this.entityTypeName == 'Student Details';
    },
    canManageAttendance(item) {
      if (!this.isEnrollmentRecord()) return false;
      if (!this.section || this.section.recordIsLocked === true) return false;
      if (item.status != 'Registered' || item.attendance == 'Absent' || item.attendance == 'Completed') return false;
      if (this.section && this.section.trainingEndDate) {
        const tEndDate = new Date(this.section.trainingEndDate);
        if (tEndDate.getTime() < Date.now()) return true;
      }
      return false;
    },
    async setAttendance(item, status, statusText) {
      this.isBusy = true;
      const body = {
        enrollmentRecordIds: [item.id],
        status: status
      };
      let confirmed = true;
      if (status === 1 || status === 3) {
        confirmed = await new Promise((resolve, reject) => {
          const options = { title: 'Confirm Attendance Status', okLabel: 'Confirm', cancelLabel: 'Cancel' };
          const msg =
            '<p>Are you sure you want to mark ' +
            item.userName +
            ' as "' +
            statusText +
            '" ?</p><p>* You will not be able to make changes once attendance status has been submitted.</p><p>Thanks,</p><p>Alabama Pathways Team';
          this.$dialogs
            .confirm(msg, options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
      }
      if (confirmed) {
        this.$store.dispatch('setAttendanceRequest', body).then((result) => {
          this.reload(result);
        });
      }
      this.isBusy = false;
    },
    testHideForm(uploaded) {
      if (this.isResumeRecord() && !uploaded) {
        this.showForm = false;
        this.showAltForm = true;
      } else {
        if (this.isReferenceRecord() && !uploaded) {
          this.showForm = false;
          this.showAltForm = true;
        } else {
          if (this.isEnrollmentRecord()) {
            this.showForm = false;
          } else {
            this.showForm = true;
            this.showAltForm = false;
            this.inForm = true;
          }
        }
      }
    },
    testShowAltForm(data) {
      if (!this.isResumeRecord() && !this.isReferenceRecord()) this.showAltForm = false;
      else {
        if (this.isResumeRecord()) {
          this.activeid = data.resumeId;
          this.altFormDesc = data.description;
          this.altFormPublished = data.isPublished;
          this.canEditRecord = !data.isPublished;
          this.fileKey = data.fileKey;
          this.altFormSections = {
            education: data.userEducation,
            employment: data.userEmployments,
            certifications: data.userCertifications,
            communityService: data.userCommunityServices,
            memberships: data.userMemberships,
            references: data.userReferences
          };
          this.showAltForm = true;
        } else {
          //this is a Reference
          if (data) {
            this.showForm = false;
            this.showAltForm = true;
          } else {
            this.showForm = true;
            this.showAltForm = false;
          }
          this.inForm = true;
        }
      }
    },
    showSearchToggle() {
      return this.isEnrollmentRecord() && this.activeid == 0 && !this.inForm && this.section;
    },
    showReferenceToggle() {
      return this.isReferenceRecord() && this.activeid == 0 && !this.inForm;
    },
    showResumeToggle() {
      return this.isResumeRecord() && this.activeid == 0 && !this.inForm;
    },
    async rolePromotionActionReceived(data) {
      this.gridData.forEach((item) => {
        if (item.id === data.action.employmentRecord.employmentRecordId) {
          this.loadSummary();
          return;
        }
      });
    }
  },
  async created() {
    this.pageSize = this.basePageSize;
    this.currentPage = this.baseCurrentPage;
    this.$messageHub.$on('role-promotion-action-received', this.rolePromotionActionReceived);
    this.localParentProps = this.parentProps;
    this.localPrimaryEntity = this.primaryEntity;
    await this.loadSummary();
    if (this.columns && this.columns.length > 0) {
      let initialSortCol = this.columns.find((column) => column.initialSort);
      if (!initialSortCol) initialSortCol = this.columns[0];
      this.sortColumn = initialSortCol.columnName;
      if (
        initialSortCol.initialSortDirection &&
        initialSortCol.initialSortDirection == this.sortDirections.descendingNumeric
      )
        this.sortDirection = this.sortDirections.descending;
      else this.sortDirection = this.sortDirections.ascending;
    }
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$messageHub.$off('role-promotion-action-received', this.rolePromotionActionReceived);
    this.localParentProps = this.parentProps;
  }
};
</script>
